import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
// import { RequirePermission } from '@backstage/plugin-permission-react';
// import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import {
  catalogGraphPlugin,
  CatalogGraphPage,
} from '@backstage/plugin-catalog-graph';
import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { ExplorePage, explorePlugin } from '@backstage/plugin-explore';
import { explorePage } from './components/explore/ExplorePage';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import * as plugins from './plugins';
import { danfossTheme } from './lib/danfossTheme';
import { UnifiedThemeProvider } from '@backstage/theme';
import LightIcon from '@material-ui/icons/WbSunny';
import { TechInsightsPage } from '@internal/backstage-plugin-tech-insights';
import { InfraWalletPage } from '@electrolux-oss/plugin-infrawallet';
import * as Sentry from '@sentry/react';
import { TestkubePage } from '@internal/backstage-plugin-testkube';
import { CorootPage, OpenMetaDataPage } from './components/apps';

const azureAuthProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'Microsoft AD',
  message: 'Sign in using Danfoss AD',
  apiRef: microsoftAuthApiRef,
};

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  themes: [
    {
      id: 'danfossTheme',
      title: 'Danfoss Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={danfossTheme} children={children} />
      ),
    },
  ],
  components: {
    SignInPage: props => (
      <SignInPage {...props} providers={[azureAuthProvider]} />
    ),
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
    bind(explorePlugin.externalRoutes, {
      catalogEntity: catalogPlugin.routes.catalogEntity,
    });
  },
});

// const AppProvider = app.getProvider();
// const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/home" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage pagination />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route>

    <Route
      path="/tech-radar"
      element={
        <TechRadarPage
          id="alsense"
          width={1500}
          height={800}
          title="Alsense Tech-Radar"
          subtitle="Overview of technologies to choose"
          pageTitle="Tech-Radar"
        />
      }
    />
    <Route path="/catalog-import" element={<CatalogImportPage />} />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/score-board" element={<TechInsightsPage />} />
    <Route path="/qeta" element={<QetaPage title="Questions" />} />
    <Route path="/toolbox" element={<ToolboxPage />} />
    <Route path="/datacatalog" element={<OpenMetaDataPage />} />
    <Route path="/infrawallet" element={<InfraWalletPage />} />
    <Route path="/testkube" element={<TestkubePage />} />
    <Route path="/coroot" element={<CorootPage />} />
  </FlatRoutes>
);

Sentry.init({
  dsn: 'https://e5090362d3ee6ad65a0c4e23d1cfdce0@sentry.enspire.danfoss.com/30',
  integrations: [],
});

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
